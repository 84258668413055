import React, { useState, useEffect } from "react";
import About from "./components/about/About";
import Address from "./components/address/Address";
import Certificaten from "./components/certificaten/Certificaten";
import Contact from "./components/contact/Contact";
import Erkenning from "./components/erkenning/Erkenning";
import Eurogarant from "./components/eurogarant/Eurogarant";
import Footer from "./components/footer/Footer";
import Hero from "./components/hero/Hero";
import Hoe from "./components/hoe/Hoe";
import Navbar from "./components/navbar/Navbar";
import Preloader from "./components/preloader/Preloader";
import Services from "./components/services/Services";
import Vervangwagen from "./components/vervangwagen/Vervangwagen";
import Welcome from "./components/welcome/Welcome";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function App() {
  const [showPreloader, setShowPreloader] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiKey = process.env.REACT_APP_APIKEY;
        const response = await fetch(
          "https://carrosserie-api-58514c25ed4b.herokuapp.com/popup",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "api-key": apiKey,
            },
          }
        );

        // assign Data
        const data = await response.json();
        const arrayData = Object.values(data);
        // Show preloader
        if (arrayData[0]) {
          setShowPreloader(false);
        }
        // Popup
        if (arrayData[0].isActive && arrayData[0]) {
          withReactContent(Swal).fire({
            title: arrayData[0].title,
            color: "#212121",
            text: arrayData[0].description,
            confirmButtonColor: "#f22a2a",
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="App">
      {showPreloader && <Preloader className="absolute" />}
      <Navbar />
      <Hero />
      <Address />
      <Welcome />
      <Erkenning />
      <Certificaten />
      <About />
      <Services />
      {/* <Vacatures /> */}
      <Hoe />
      <Vervangwagen />
      <Eurogarant />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
