import "./Preloader.css";

export default function Preloader() {
  return (
    <>
      <div className="loader">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="spinner">
              <div className="bounce1"></div>
              <div className="bounce2"></div>
              <div className="bounce3"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
