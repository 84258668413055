import React from "react";
import ReactPlayer from "react-player";

const AanHetWerk = () => {
  return (
    <div className="video-player" style={{ backgroundColor: "#212121" }}>
      <ReactPlayer
        url="/videos/werk.webm"
        width={"100%"}
        height={"100vh"}
        controls={false}
        playing={true}
        loop={true}
        muted={true}
      />
    </div>
  );
};

export default AanHetWerk;
