import "./Erkenning.css";
import Baloise from "../../assets/img/erkenningen/Baloise.png";
import Belfius from "../../assets/img/erkenningen/Belfius.jpg";
import Allianz from "../../assets/img/erkenningen/Allianz.jpg";
import Dvv from "../../assets/img/erkenningen/Dvv.jpg";
import NN from "../../assets/img/erkenningen/NN.jpg";
import YUZZU from "../../assets/img/erkenningen/YUZZU.png";
import AXA from "../../assets/img/erkenningen/AXA.png";
import AG from "../../assets/img/erkenningen/AG.0290bba3ce408ce766b3-removebg-preview.png";
import KBC from "../../assets/img/erkenningen/cq5dam.web.480.9999.png";

export default function Erkenning() {
  return (
    <section id="erkenning" className="process-area pt-100 pb-70">
      <div className="section-title">
        <h2>Erkend hersteller voor:</h2>
      </div>
      <div className="container flex w-100 m-auto">
        <div
          className="col"
          style={{ textAlign: "center", width: "100%", margin: "auto" }}
        >
          <img src={Baloise} style={{ width: "280px" }} alt="" />
        </div>
        <div
          className="col"
          style={{ textAlign: "center", width: "100%", margin: "auto" }}
        >
          <img src={Belfius} style={{ width: "280px" }} alt="" />
        </div>
        <div
          className="col"
          style={{ textAlign: "center", width: "100%", margin: "auto" }}
        >
          <img src={Allianz} style={{ width: "280px" }} alt="" />
        </div>
        <div
          className="col"
          style={{ textAlign: "center", width: "100%", margin: "auto" }}
        >
          <img src={Dvv} style={{ width: "280px" }} alt="" />
        </div>
        <div
          className="col"
          style={{ textAlign: "center", width: "100%", margin: "auto" }}
        >
          <img src={NN} style={{ width: "280px" }} alt="" />
        </div>
      </div>
      <div className="container flex w-100 m-auto">
        <div
          className="col"
          style={{ textAlign: "center", width: "100%", margin: "auto" }}
        >
          <img src={AXA} style={{ width: "180px", borderRadius: 8 }} alt="" />
        </div>
        <div
          className="col"
          style={{ textAlign: "center", width: "100%", margin: "auto" }}
        >
          <img src={YUZZU} style={{ width: "180px", borderRadius: 8 }} alt="" />
        </div>
        <div
          className="col"
          style={{ textAlign: "center", width: "100%", margin: "auto" }}
        >
          <img src={AG} style={{ width: "180px", borderRadius: 8 }} alt="" />
        </div>
        <div
          className="col"
          style={{ textAlign: "center", width: "100%", margin: "auto" }}
        >
          <img src={KBC} style={{ width: "180px", borderRadius: 8 }} alt="" />
        </div>
      </div>
    </section>
  );
}
